import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import InputField from "./InputField";
import SelectMenu from "./SelectMenu";
import View from "./View";
import PopUpMenu from "./PopUpMenu";
import PopUpMenuItem from "./PopUpMenuItem";
import Touchable from "./Touchable";
import Icon from "./Icon";
import AnimatedView from "./AnimatedView";
import AnimatedText from "./AnimatedText";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

export default R14.connectForm(
  /**
   * A select menu field component
   */
  class SelectMenuField extends React.Component {
    static propTypes = {
      /** Name for the select menu. */
      name: PropTypes.string.isRequired,
      /** Label for the select menu. */
      label: PropTypes.string,
      /** Array of label / value objects. */
      items: PropTypes.array,
      /** Value of the select menu. */
      value: PropTypes.string,
      /** The select menu varient to use */
      variant: PropTypes.oneOf(["outlined", "filled"]),
      /** If true, disable the select menu */
      disabled: PropTypes.bool,
      /** If true, displays a secure select menu for things like passwords */
      secure: PropTypes.bool,
      /** Handler to be called when the selects value is channed  */
      onValueChange: PropTypes.func,
      /** Handler to be called when the user taps the select menu  */
      onPress: PropTypes.func,
      /** Handler to be called when the select menu gains focus  */
      onFocus: PropTypes.func,
      /** Handler to be called when the select menu loses focus  */
      onBlur: PropTypes.func,
      /** Allow multiple selections using checkboxes  */
      multiple: PropTypes.bool,
      /** Allow for search selections  */
      searchable: PropTypes.bool,
      /** Dynamically load selections, required for searchable  */
      itemLoader: PropTypes.func,
      /** Automatically call itemLoader on load  */
      autoLoad: PropTypes.bool,
      /** The name of the icon to be displayed to the left of the select menu  */
      icon: PropTypes.string,
      /** The name of the icon to be displayed to the right of the select menu  */
      iconRight: PropTypes.string,
      /** Style to be applied to the input field. */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Helper text style to be applied to the input field helper. */
      helperTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Label text style to be applied to the input field helper label. */
      labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Uses only values as input and output. Default is to use label, value. */
      useValuesOnly: PropTypes.bool,
      /** Icon used for control */
      controlIcon: PropTypes.string,
      /** Disable the control animation when opened */
      disableControlAnimation: PropTypes.bool,
    };
    static defaultProps = {
      variant: "outlined",
      multiple: false,
      useValuesOnly: false,
    };
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleSearchChangeText = this.handleSearchChangeText.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
    }
    handleFocus() {
      this.elmt.setFocus(true);
      if (this.props.onFocus) this.props.onFocus(this.elmt);
    }
    handleBlur() {
      this.elmt.setBlur(true);
      if (this.props.onBlur) this.props.onBlur(this.elmt);
    }
    handleOpen() {
      if (this.props.autoLoad && !this.elmt.loaded) {
        this.elmt.setLoaded(true);
        this.loadItems({});
      }
    }
    async loadItems(params) {
      if (!this.props.itemLoader) return false;
      this.elmt.setLoading(true);
      let items = await this.props.itemLoader(params);
      this.elmt.setLoading(false);
      if (items) this.elmt.setItems(items);
    }
    handleSearchChangeText(text) {
      // if (!this.props.autoLoad && !text) return false;
      this.loadItems(text ? { search: text } : {});
    }
    handleValueChange(vals, mode) {
      let { value, label } = vals;
      if (this.props.multiple) {
        let values = this.elmt.value || [];
        if (!Array.isArray(values)) values = [values];
        let valueIndex = -1;
        let updatedValue = null;
        switch (mode) {
          case "LABEL_VALUE":
            for (let i in values) {
              if (value === values[i].value) {
                valueIndex = i;
                break;
              }
            }
            // return all values, pass through user given
            updatedValue = vals;
            break;
          default:
            valueIndex = values.indexOf(value);
            updatedValue = value;
        }
        if (valueIndex === -1) values.push(updatedValue);
        else values.splice(valueIndex, 1);

        if (values.length) this.elmt.setValue(values);
        else this.elmt.setValue(null);

        switch (mode) {
          case "LABEL_VALUE":
            this.props.onValueChange &&
              this.props.onValueChange(
                values.length ? updatedValue : null,
                this.elmt,
                this.elmt.form
              );

            this.elmt.form.triggerValueChange(
              values.length ? updatedValue : null,
              this.elmt
            );
            break;
          default:
            this.props.onValueChange &&
              this.props.onValueChange(
                values.length ? updatedValue : null,
                //values.length ? label : null,
                this.elmt,
                this.elmt.form
              );
            this.elmt.form.triggerValueChange(
              values.length ? updatedValue : null,
              this.elmt
            );
        }
      } else {
        switch (mode) {
          case "LABEL_VALUE":
            this.elmt.setValue(vals.value ? vals : null);
            this.props.onValueChange &&
              this.props.onValueChange(vals, this.elmt, this.elmt.form);
            this.elmt.form.triggerValueChange(vals, this.elmt);
            break;
          default:
            this.elmt.setValue(value || null);
            this.props.onValueChange &&
              this.props.onValueChange(value, label, this.elmt, this.elmt.form);
            this.elmt.form.triggerValueChange(value, this.elmt);
        }
      }
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    render() {
      let styles = this.styles();
      let variantStyles = this.variantStyles();
      return (
        <InputField
          {...this.props}
          element={this.elmt}
          InputComponent={
            <SelectMenu
              name={this.elmt.name}
              label={this.props.label}
              items={this.elmt.items}
              ref={this.elmt.ref}
              placeholder={
                this.elmt.isActive && this.props.placeholder
                  ? this.props.placeholder
                  : null
              }
              FooterComponent={this.props.FooterComponent}
              showDefaultBlankItem={!this.elmt.required}
              useValuesOnly={this.props.useValuesOnly ? true : false}
              disableControlAnimation={
                this.props.disableControlAnimation ? true : false
              }
              controlIcon={this.props.controlIcon || null}
              value={this.elmt.value}
              initialValueLabels={this.props.initialValueLabels}
              multiple={this.props.multiple}
              searchable={this.props.searchable}
              itemLoader={this.props.itemLoader}
              onSearchChangeText={this.handleSearchChangeText}
              onValueChange={this.handleValueChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onOpen={this.handleOpen}
              disabled={this.elmt.disabled}
              loading={this.elmt.loading}
              autoFocus={this.props.autoFocus || null}
              openOnFocus={this.props.openOnFocus || false}
              style={[styles.selectMenu, variantStyles.selectMenu]}
              valueLabelTextStyle={[
                styles.selectValueLabelTextStyle,
                this.props.valueLabelTextStyle,
              ]}
              popUpMenuStyle={this.props.popUpMenuStyle}
              iconColor={this.elmt.isFocused && Colors.primary}
            />
          }
        />
      );
    }
    variantStyles() {
      switch (this.props.variant) {
        case "outlined":
          return StyleSheet.create({
            selectMenu: {
              // paddingTop: 1,
              // paddingBottom: 1,
              minHeight: 52,
              height: "auto",
              paddingLeft: this.props.icon ? 43 : 1,
              paddingRight: this.props.iconRight ? 43 : 1,
              lineHeight: 30,
            },
          });
          break;
        default:
          return StyleSheet.create({
            selectMenu: {
              paddingTop: 20,
              paddingBottom: 5,
              height: 55,
              lineHeight: 30,
            },
          });
      }
    }
    styles() {
      return StyleSheet.create({
        selectMenu: {
          margin: 0,
          width: "100%",
        },
        selectValueLabelTextStyle: {
          lineHeight: 30,
        },
        selectMenuIcon: {
          // top: 0
        },
      });
    }
  }
);
