import React from "react";
import R14, {
  StyleSheet,
  Colors,
  Scroller,
  Image,
  Surface,
  View,
  Text,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  ActivityIndicator,
  FadeView,
  Dialog,
  TextInputField,
  Form,
  HiddenInputField,
  Button,
  SubmitButton,
  Chip,
} from "../core";
export default R14.connect(
  class DocumentSetPageScroller extends React.Component {
    static propTypes = {};
    static defaultProps = {
      thumbnailHeight: 129,
      thumbnailWidth: 108,
    };
    constructor(props) {
      super(props);
      this.DIALOG_MODE_GO_TO_ACTIVE_PAGE = "GO_TO_ACTIVE_PAGE";
      this.DIALOG_MODE_GO_TO_PAGE = "GO_TO_PAGE";
      this.DIALOG_MODE_COPY_TO_PAGE = "COPY_TO_PAGE";
      this.DIALOG_MODE_MOVE_TO_PAGE = "MOVE_TO_PAGE";
      this.handlePress = this.handlePress.bind(this);
      this.handlePageNavigatorNextPress =
        this.handlePageNavigatorNextPress.bind(this);
      this.handlePageNavigatorPrevPress =
        this.handlePageNavigatorPrevPress.bind(this);
      this.handlePageNavigatorGoToActivePagePress =
        this.handlePageNavigatorGoToActivePagePress.bind(this);
      this.handlePageNavigatorGoToPagePress =
        this.handlePageNavigatorGoToPagePress.bind(this);
      this.handlePageNavigatorMoveToPagePress =
        this.handlePageNavigatorMoveToPagePress.bind(this);
      this.handlePageNavigatorCopyToPagePress =
        this.handlePageNavigatorCopyToPagePress.bind(this);
      this.handlePageActionDialogClosePress =
        this.handlePageActionDialogClosePress.bind(this);
      // this.handlePageActionDialogOkPress =
      //   this.handlePageActionDialogOkPress.bind(this);
      this.handlePageActionFormSubmit =
        this.handlePageActionFormSubmit.bind(this);
      this.state = {
        loading: false,
        dialogMode: null,
      };
    }
    get thumbnailHeight() {
      return this.props.thumbnailHeight;
    }
    get thumbnailWidth() {
      return this.props.thumbnailWidth;
    }

    get pageActionForm() {
      return this.props.app.ui.form("dialogActionForm");
    }
    openDialog(dialogMode) {
      this.setState({ dialogMode });
    }
    closeDialog() {
      this.setState({ dialogMode: null });
    }
    handlePress({ pageNumber }) {
      // R14.instance.app.ui.progressIndicator.show();
      this.props.documentSet.navToPage(pageNumber);
      // R14.instance.app.ui.progressIndicator.hide({ timeout: 500 });
    }
    async handlePageNavigatorNextPress() {
      this.setState({ loading: true });
      await this.props.documentSet.loadNextDocuments();
      this.setState({ loading: false });
    }
    async handlePageNavigatorPrevPress() {
      this.setState({ loading: true });
      await this.props.documentSet.loadPrevDocuments();
      this.setState({ loading: false });
    }
    async handlePageNavigatorGoToActivePagePress() {
      if (this.activeDocumentLoaded) return false;
      this.props.documentSet.currentPage &&
        (await this.props.documentSet.loadDocuments({
          documentUuid: this.props.documentSet.currentPage.documentUuid,
        }));
    }
    handlePageNavigatorGoToPagePress() {
      this.openDialog(this.DIALOG_MODE_GO_TO_PAGE);
    }
    handlePageNavigatorMoveToPagePress() {
      this.openDialog(this.DIALOG_MODE_MOVE_TO_PAGE);
    }
    handlePageNavigatorCopyToPagePress() {
      this.openDialog(this.DIALOG_MODE_COPY_TO_PAGE);
    }
    handlePageActionDialogClosePress() {
      this.closeDialog();
    }
    // async handlePageActionDialogOkPress() {
    //   alert("submit the form");
    // }
    async handlePageActionFormSubmit(form) {
      let vals = form.values;
      this.props.app.ui.progressIndicator.show();
      try {
        switch (vals.mode) {
          case this.DIALOG_MODE_GO_TO_PAGE:
            this.props.documentSet.goToDocumentPage(vals.page);
            break;
          case this.DIALOG_MODE_MOVE_TO_PAGE:
            await this.props.documentSet.moveDocument(vals.page);
            break;
          case this.DIALOG_MODE_COPY_TO_PAGE:
            await this.props.documentSet.copyDocument(vals.page);
            break;
        }
      } catch (err) {}
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.closeDialog();
    }
    createPageImageKey(page) {
      return `pageImage${page.number}`;
    }
    calculateThumbnailSize(image) {
      let thumbnailHeight = this.thumbnailHeight;
      let height = image.height;
      let width = image.width;
      let rotate = image.rotate || 0;
      switch (rotate) {
        case 90:
        case 270:
        case -90:
        case -270:
          height = image.width;
          width = image.height;
          break;
      }
      if (!height && !width)
        return { height: thumbnailHeight, width: thumbnailHeight };

      let scale = thumbnailHeight / (height >= width ? height : width);

      return {
        height: height * scale,
        width: width * scale,
      };
    }
    getIndicatorStyle(page, active = false, activeState = null) {
      let activeStr = active ? "Active" : "";
      let styleName = `scrollerItemIndicator${activeStr}`;
      switch (page.metadata.state) {
        case this.props.app.dm.manualEntry.STATE_REVIEW:
          styleName = `scrollerItemIndicator${activeStr}Review`;
          break;
        default:
          if (activeState === "SELECTED")
            styleName = `scrollerItemIndicator${activeStr}Edit`;
      }
      return styles[styleName] || null;
    }
    renderItem(page, dynamicStyles) {
      let { height, width } = this.calculateThumbnailSize(page.image);
      return (
        <Scroller.Item
          key={page.documentUuid}
          name={page.key}
          style={[
            [
              styles.scrollerItem,
              dynamicStyles[`${this.createPageImageKey(page)}ScrollerItem`],
            ],
          ]}
          indicatorStyle={this.getIndicatorStyle(page)}
          indicatorActiveStyle={(activeItemState) =>
            this.getIndicatorStyle(page, true, activeItemState)
          }
          onPress={this.handlePress}
          pageNumber={page.number}
          indicatorPosition='top'
        >
          <Surface
            style={[
              styles.surface,
              // styles.scrollerItem,
              dynamicStyles[`${this.createPageImageKey(page)}ScrollerItem`],
            ]}
            elevation={4}
          >
            <Image
              resizeMode='contain'
              lazyLoad
              // autosize
              // style={[styles.image]}
              height={height}
              width={width}
              auth={
                this.props.documentSet.documentImageStorageMode !==
                this.props.app.dm.manualEntry
                  .DOCUMENT_IMAGE_STORAGE_MODE_DATASET
              }
              style={[
                styles.documentThumbnailImage,
                dynamicStyles[`${this.createPageImageKey(page)}Image`],
              ]}
              source={{
                uri: page.image.url,
              }}
            />
          </Surface>
          <View
            key='pageNumber'
            style={[
              styles.scrollerItemPageNumber,
              //this.getIndicatorStyle(page, active),
            ]}
          >
            <Text style={styles.scrollerItemPageNumberText}>{page.number}</Text>
          </View>
        </Scroller.Item>
      );
    }
    itemLoadingRenderer(itemIndex, dynamicStyles) {
      // console.log("FIX THIS HACKY STYLE ISSUE");
      let testStyles = StyleSheet.create({
        scrollerItemLoading: {
          position: "relative",
          flex: 1,
          height: this.props.thumbnailHeight,
          width: this.props.thumbnailWidth,
          ...StyleSheet.margin(8, 8, 8, 0),
          // backgroundColor: Colors.surface,
        },
      });
      // dynamicStyles.scrollerItemLoading = {
      //   position: "relative",
      //   flex: 1,
      //   height: this.props.thumbnailHeight,
      //   width: this.props.thumbnailWidth,
      //   ...StyleSheet.margin(8, 8, 8, 0),
      //   // backgroundColor: Colors.surface,
      // };
      return (
        <View
          key={`scrollerItemLoading${itemIndex}`}
          style={[testStyles.scrollerItemLoading]}
        >
          <Surface
            style={[styles.surface, styles.surfaceLoading]}
            elevation={4}
          ></Surface>
          <View key='pageNumber' style={[styles.scrollerItemPageNumber]}>
            <Text
              style={[
                styles.scrollerItemPageNumberText,
                styles.scrollerItemPageNumberLoadingText,
              ]}
            >
              {itemIndex + 1}
            </Text>
          </View>
        </View>
      );
    }
    renderItems() {
      return this.props.documentSet.pages.map((page) => {
        return this.renderItem(page);
      });
    }
    get pageInfo() {
      return this.props.documentSet.documentImagesPageInfo;
    }
    get totalPages() {
      return this.props.documentSet.totalPages;
    }
    get activeDocumentLoaded() {
      return (
        this.props.documentSet.currentPage &&
        this.props.documentSet.currentPage.documentUuid &&
        this.props.documentSet.documentLoaded(
          this.props.documentSet.currentPage.documentUuid
        )
      );
    }
    renderDialogTitle(dialogMode) {
      let ret = null;
      switch (dialogMode) {
        case this.DIALOG_MODE_GO_TO_ACTIVE_PAGE:
          ret = "Go to Active Page";
          break;
        case this.DIALOG_MODE_GO_TO_PAGE:
          ret = "Go to Page";
          break;
        case this.DIALOG_MODE_COPY_TO_PAGE:
          ret = "Copy to Page";
          break;
        case this.DIALOG_MODE_MOVE_TO_PAGE:
          ret = "Move to Page";
          break;
      }
      return ret;
    }
    renderPageActionDialog() {
      return (
        <Dialog
          name='pageActionDialog'
          style={styles.pageActionDialog}
          title={this.renderDialogTitle(this.state.dialogMode) || "Page Action"}
          visible={this.state.dialogMode !== null}
          onClosePress={this.handlePageActionDialogClosePress}
          onBackPress={this.handlePageActionDialogClosePress}
          onCancelPress={this.handlePageActionDialogClosePress}
          onBackdropPress={this.handlePageActionDialogClosePress}
          // onOkPress={this.handlePageActionDialogOkPress}
          hideOkButton
          hideCancelButton
        >
          <Form
            name='pageActionForm'
            style={styles.pageActionForm}
            onSubmit={this.handlePageActionFormSubmit}
            validateBeforeSubmit
            initialValues={{ mode: this.state.dialogMode }}
            validators={{
              page: (val) => {
                let valid = val > 0 && val <= this.totalPages;
                return valid
                  ? true
                  : { error: `Must be between 1 and ${this.totalPages}.` };
              },
            }}
            controlsBottomRight={[
              <Button
                key='cancel'
                title='Cancel'
                variant='text'
                onPress={this.handlePageActionDialogClosePress}
              />,
              <SubmitButton title='Continue' key='submit' />,
            ]}
          >
            <HiddenInputField name='mode' />
            <TextInputField
              name='page'
              autoFocus
              label={this.renderDialogTitle(this.state.dialogMode)}
              required='Please enter a page.'
              validator={["positiveInt", "page"]}
            />
          </Form>
        </Dialog>
      );
    }
    renderNavigator() {
      let docRangeStart =
        (this.pageInfo.page - 1) * this.pageInfo.resultsPerPage + 1;
      let docRangeEnd = docRangeStart + this.pageInfo.resultsPerPage - 1;
      if (docRangeEnd > this.totalPages) docRangeEnd = this.totalPages;

      return (
        <View style={styles.pageNavigator}>
          <Text style={styles.pageNavigatorText}>
            Viewing <Text style={styles.boldText}>{this.totalPages}</Text>{" "}
            Document{this.totalPages > 1 ? "s" : ""}
          </Text>
          {/* <IconButton
            style={styles.pageNavigatorIconButton}
            disabled={docRangeStart <= 1}
            icon='keyboardArrowLeft'
            onPress={this.handlePageNavigatorPrevPress}
          />
          <IconButton
            style={styles.pageNavigatorIconButton}
            disabled={docRangeEnd >= this.totalPages}
            icon='keyboardArrowRight'
            onPress={this.handlePageNavigatorNextPress}
          /> */}
          <PopUpMenu
            controlIcon='dotsVertical'
            iconSize='small'
            key='menu'
            direction='downLeft'
            controlIconStyle={styles.pageNavigatorIconButton}
          >
            {!this.activeDocumentLoaded && (
              <PopUpMenuItem
                label={this.renderDialogTitle(
                  this.DIALOG_MODE_GO_TO_ACTIVE_PAGE
                )}
                key='active'
                onPress={this.handlePageNavigatorGoToActivePagePress}
              />
            )}
            <PopUpMenuItem
              label={this.renderDialogTitle(this.DIALOG_MODE_GO_TO_PAGE)}
              key='go'
              onPress={this.handlePageNavigatorGoToPagePress}
            />
            {this.props.documentSet.hasAppFeature("enableDocumentMove") && (
              <PopUpMenuItem
                label={this.renderDialogTitle(this.DIALOG_MODE_MOVE_TO_PAGE)}
                key='move'
                onPress={this.handlePageNavigatorMoveToPagePress}
              />
            )}
            {this.props.documentSet.hasAppFeature("enableDocumentCopy") && (
              <PopUpMenuItem
                label={this.renderDialogTitle(this.DIALOG_MODE_COPY_TO_PAGE)}
                key='copy'
                onPress={this.handlePageNavigatorCopyToPagePress}
              />
            )}
          </PopUpMenu>
        </View>
      );
    }
    render() {
      const dynamicStyles = this.createDynamicStyles();
      return (
        <Surface elevation={6} style={styles.documentSetPageScroller}>
          {this.renderPageActionDialog()}
          {this.renderNavigator()}
          {false && this.props.documentSet.state.documentImagesLoading && (
            <ActivityIndicator
              size='large'
              containerStyle={styles.activityIndicator}
            />
          )}
          <FadeView
            visible={
              true || !this.props.documentSet.state.documentImagesLoading
            }
            unmountOnExit
          >
            <Scroller
              name='pageScroller'
              style={styles.scroller}
              contentStyle={styles.scrollerContent}
              direction='horizontal'
              initialData={this.props.documentSet.pages}
              initialPage={this.pageInfo.page}
              itemsPerPage={this.pageInfo.resultsPerPage}
              totalItems={this.totalPages}
              autoScroll={false}
              dataLoader={async (page) => {
                // const documents =
                const documents = await this.props.documentSet.loadDocuments({
                  page,
                  loading: false,
                });
                // console.log("CHECK", documents);
                return documents;
              }}
              onRemoveItem={(page) =>
                this.props.documentSet.removePageInstance(page)
              }
              itemRenderer={(item) => this.renderItem(item, dynamicStyles)}
              itemLoadingRenderer={(itemIdx) =>
                this.itemLoadingRenderer(itemIdx, dynamicStyles)
              }
              itemSize={this.thumbnailWidth + 8}
              activeItemName={
                this.props.documentSet.currentPage
                  ? this.props.documentSet.currentPage.key
                  : null
              }
              // activeItemState={
              //   this.props.documentSet.currentAnnotation &&
              //   this.props.documentSet.currentAnnotation.documentUuid ===
              //     page.documentUuid
              //     ? "SELECTED"
              //     : "ACTIVE"
              // }
            >
              {/* {false && this.renderItems()} */}
            </Scroller>
          </FadeView>
        </Surface>
      );
    }
    createDynamicStyles() {
      let dynamicStyles = {};
      this.props.documentSet.pages.forEach((page, idx) => {
        let { width, height } = this.calculateThumbnailSize(page.image);
        dynamicStyles[`${this.createPageImageKey(page)}ScrollerItem`] = {
          height: this.thumbnailHeight,
          width: this.thumbnailWidth,
          flex: 1,
          alignItems: "center",
        };
        let rotate = page.image.rotate;
        let rotateTransforms = [];
        switch (rotate) {
          case 90:
          case 270:
          case -90:
          case -270:
            rotateTransforms = [
              {
                translateY: height / 2,
              },
              {
                rotate: `90deg`,
              },
            ];
            break;
          case 180:
          case -180:
            rotateTransforms = [
              {
                rotate: `180deg`,
              },
            ];
            break;
          // case 270:
          //   rotateTransforms = [
          //     {
          //       rotate: `${rotate}deg`,
          //     },
          //     {
          //       translateX: -(width / 2),
          //     },
          //   ];
          //   break;
        }
        dynamicStyles[`${this.createPageImageKey(page)}Image`] = {
          transform: rotateTransforms,
        };
        dynamicStyles.scrollerItemLoading = {
          position: "relative",
          flex: 1,
          height: this.props.thumbnailHeight,
          width: this.props.thumbnailWidth,
          ...StyleSheet.margin(8, 8, 8, 0),
          // backgroundColor: Colors.surface,
        };
      });
      return StyleSheet.create(dynamicStyles);
    }
  }
);

const styles = StyleSheet.create({
  documentThumbnailImage: {},
  documentSetPageScroller: {
    height: 180,
    position: "relative",
    // backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.2),
    ...StyleSheet.padding(0),
  },
  pageNavigator: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    ...StyleSheet.padding(0, 0, 0, 0),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  pageNavigatorText: {
    ...StyleSheet.padding(0, 8, 0, 0),
  },
  scrollerItemPageNumberText: {
    fontSize: 12,
    fontWeight: "500",
  },
  scrollerItemPageNumber: {
    position: "absolute",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    zIndex: 10,
    bottom: 0,
    right: 0,
    left: 0,
    height: 20,
    backgroundColor: StyleSheet.color(Colors.surface).rgba(0.6),
    ...StyleSheet.padding(3, 0, 2, 0),
  },
  boldText: {
    fontWeight: 600,
  },
  pageNavigatorIconButton: {
    ...StyleSheet.margin(0, 0, 0, 0),
  },
  scroller: {
    height: 144,
    ...StyleSheet.margin(0, 8, 0, 8),
  },
  scrollerContent: {
    // alignItems: "center",
    justifyContent: "flex-start",
  },
  scrollerItem: {
    // height: 160,
    flex: 0,
    flexShrink: 0,
    ...StyleSheet.padding(0),
    ...StyleSheet.margin(8, 8, 8, 0),
    // backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  activityIndicator: {
    position: "absolute",
    ...StyleSheet.padding(36, 0, 0, 0),
    ...StyleSheet.absoluteFill,
    zIndex: 2,
  },
  pageActionForm: {
    ...StyleSheet.padding(0, 0, 16, 0),
    ...StyleSheet.margin(0),
  },
  pageActionDialog: {
    minHeight: 220,
  },
  scrollerItemIndicator: {
    backgroundColor: StyleSheet.color(Colors.surface).rgba(0.4),
  },
  scrollerItemIndicatorActive: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.8),
  },
  scrollerItemIndicatorEdit: {
    backgroundColor: StyleSheet.color(Colors.yellow).rgba(0.6),
  },
  scrollerItemIndicatorActiveEdit: {
    backgroundColor: StyleSheet.color(Colors.yellow).rgba(0.8),
  },
  scrollerItemIndicatorReview: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.5),
  },
  scrollerItemIndicatorActiveReview: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(1),
  },
  surface: {
    flex: 1,
    ...StyleSheet.padding(0),
  },
  surfaceLoading: {
    // backgroundColor: StyleSheet.color(Colors.onSurface).rgba(1),
    backgroundImage: `linear-gradient(to bottom right, ${StyleSheet.color(
      Colors.onSurface
    ).rgba(0.03)}, transparent)`,
  },
  scrollerItemPageNumberLoadingText: {
    fontSize: 12,
    fontWeight: "500",
    color: StyleSheet.color(Colors.onSurface).rgba(0.6),
  },
});
