import React from "react";
import PropTypes from "prop-types";
import Surface from "./Surface";
import IconButton from "./IconButton";
import View from "./View";
import Modal from "./Modal";
import StyleSheet from "./StyleSheet";
import Dimensions from "./Dimensions";
import StatusBar from "./StatusBar";
import ScrollView from "./ScrollView";
import AnimatedView from "./AnimatedView";
import InfiniteList from "./InfiniteList";

export default class PopUpMenu extends React.PureComponent {
  static propTypes = {
    /** The direction prop specifies the target direction and animation for an menu item to open.  */
    direction: PropTypes.oneOf([
      "down",
      "upRight",
      "downLeft",
      "downRight",
      "upLeft",
    ]),
    /** The control is a component used to open and close the menu  */
    Control: PropTypes.node,
    /** The controlIcon prop is used to create an icon button if control isn't given  */
    controlIcon: PropTypes.string,
    /** The controlWidthCover prop is used force the popup menu to be atleast the width of the control  */
    controlWidthCover: PropTypes.bool,
    /** The controlIconSize prop is used to set the size of the generated icon  */
    controlIconSize: PropTypes.oneOf(["small", "medium", "large"]),
    /** A StyleSheet object, or array of StyleSheet objects to apply to the icon */
    controlIconStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    // /** The controlIconColor prop is used to set the color of the generated icon  */
    // controlIconColor: PropTypes.oneOf(["small", "medium", "large"]),
    /** Content to be rendered at the top of the popupmenu  */
    HeaderComponent: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
      PropTypes.elementType,
    ]),
    FooterComponent: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
      PropTypes.elementType,
    ]),
  };
  static defaultProps = {
    direction: "downLeft",
  };
  constructor(props) {
    super(props);
    this.handleControlPress = this.handleControlPress.bind(this);
    this.handleItemPress = this.handleItemPress.bind(this);
    // this.handlePressOutside = this.handlePressOutside.bind(this);
    this.handleBackdropPress = this.handleBackdropPress.bind(this);
    this.handleLayout = this.handleLayout.bind(this);
    this.handleScrollItemLayout = this.handleScrollItemLayout.bind(this);
    this.containerRef = React.createRef();
    this.scrollViewRef = React.createRef();
    this.itemRef = React.createRef();
    this.currScrollToItem = null;
    this.ITEM_HEIGHT = 48;
    this.state = {
      isOpen: false,
      controlLayout: {
        top: 0,
        left: 0,
        height: 0,
        width: 0,
      },
      popUpLayout: {
        height: 0,
        width: 0,
      },
      scrollToOffset: null,
    };
  }
  open() {
    this.containerRef.current.measureInWindow((px, py, width, height) => {
      this.setState({
        controlLayout: {
          top: py,
          left: px,
          width: width,
          height: height,
        },
      });
      this.setState({
        isOpen: true,
      });
      if (this.props.onOpen) this.props.onOpen();
    });
  }
  close() {
    this.currScrollToItem = null;
    this.setState({
      isOpen: false,
    });
    if (this.props.onClose) this.props.onClose();
  }
  reset(options) {
    this.scrollViewRef.current &&
      this.scrollViewRef.current.reset &&
      this.scrollViewRef.current.reset(options);
  }
  handleBackdropPress() {
    this.close();
    if (this.props.onPressOutside) this.props.onPressOutside();
  }
  handleControlPress() {
    if (this.props.disabled) return false;
    if (this.state.isOpen) this.close();
    else this.open();
  }
  updateScroll() {
    if (
      this.props.itemRenderer &&
      this.props.scrollToItem &&
      this.props.scrollToItem !== this.currScrollToItem &&
      this.state.isOpen &&
      this.scrollViewRef.current
    ) {
      let itemIdx = this.props.items.findIndex(
        (item) => item.value === this.props.scrollToItem
      );
      this.currScrollToItem = this.props.scrollToItem;
      itemIdx !== null &&
        this.scrollViewRef.current.scrollTo({
          y: itemIdx * this.ITEM_HEIGHT,
          animated: false,
        });
    }
  }
  componentDidMount() {
    this.updateScroll();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.loading &&
      !this.props.loading &&
      this.scrollViewRef.current
    ) {
      setTimeout(() => this.scrollViewRef.current.reload(), 50);
    }
    this.updateScroll();
  }
  handleItemPress(value, label) {
    if (this.props.onSelect) this.props.onSelect(value, label);
    if (this.state.isOpen) this.close();
  }
  // handlePressOutside() {
  //   this.close();
  //   if (this.props.onPressOutside) this.props.onPressOutside();
  // }
  handleLayout(event) {
    this.setState({
      popUpLayout: {
        height: event.nativeEvent.layout.height || 0,
        width: event.nativeEvent.layout.width || 0,
      },
    });
  }
  handleScrollItemLayout(event) {
    !this.props.itemRenderer &&
      this.scrollViewRef.current.scrollTo({
        y: event.nativeEvent.layout.y,
        animated: false,
      });
  }
  get scrollToItem() {
    if (!("scrollToItem" in this.props)) return null;
    else return this.props.scrollToItem;
  }
  renderControl() {
    let control = null;
    if (this.props.controlIcon) {
      control = (
        <IconButton
          icon={this.props.controlIcon}
          size={this.props.controlIconSize}
          color={this.props.controlIconColor}
          style={this.props.controlIconStyle || null}
          onPress={this.handleControlPress}
        />
      );
    } else if (this.props.Control) {
      control = React.cloneElement(this.props.Control, {
        onPress: this.handleControlPress,
        ref: this._controlRef,
      });
    }
    // if (!control) throw new Error("PopUpMenu Error: No control found.");
    return control;
  }
  get exitTransformAnimation() {
    switch (this.props.direction) {
      case "down":
        return [{ translateX: 0 }, { translateY: -35 }, { scale: 0.8 }];
        break;
      case "downLeft":
        return [{ translateX: 10 }, { translateY: -20 }, { scale: 0.8 }];
        break;
      case "upLeft":
        return [{ translateX: 10 }, { translateY: 20 }, { scale: 0.8 }];
        break;
      case "downRight":
        return [{ translateX: -10 }, { translateY: -20 }, { scale: 1 }];
        break;
      case "upRight":
        return [{ translateX: -10 }, { translateY: 20 }, { scale: 1 }];
        break;
    }
  }
  render() {
    let items = [];
    let useInfiniteList = false;
    let scrollComponent = null;
    if (this.props.items) {
      if (!this.props.itemRenderer) throw new Error("itemRenderer required");
      useInfiniteList = true;
      scrollComponent = (
        <InfiniteList
          name={this.props.name}
          key='infiniteList'
          ref={this.scrollViewRef}
          initialPage={1}
          initialData={this.props.items.slice(0, 20)}
          pageLoadDelay={50}
          // onScroll={this.handleScroll}
          // onLayout={this.handleLayout}
          // onContentSizeChange={this.handleContentLayout}
          dataLoader={async (page) => {
            return this.props.items.slice((page - 1) * 20, page * 20);
          }}
          itemRenderer={(item, index) => {
            return item
              ? React.cloneElement(this.props.itemRenderer(item, index), {
                  popUpMenu: this,
                })
              : null;
          }}
          // itemLoadingRenderer={this.props.itemLoadingRenderer}
          keyExtractor={(item) => item.value}
          getItemLayout={(data, index) => ({
            length: 48,
            offset: this.ITEM_HEIGHT * index,
            index,
          })}
          itemSize={this.ITEM_HEIGHT}
          //ItemLoadingComponent={<Text>Loading...</Text>}
          totalItems={this.props.items.length}
          itemsPerPage={20}
          style={styles.scrollView}
        />
      );
    } else if (this.props.children) {
      items = (
        !Array.isArray(this.props.children)
          ? [this.props.children]
          : this.props.children
      ).map((item) => {
        return item
          ? React.cloneElement(item, {
              popUpMenu: this,
            })
          : null;
      });
      scrollComponent = (
        <ScrollView ref={this.scrollViewRef} style={styles.scrollView}>
          {items}
        </ScrollView>
      );
    }
    // let items = this.props.children || null;
    // if (items && !Array.isArray(this.props.children)) items = [items];
    //let items = (Array.isArray(this.props.children)) ? this.props.children : [this.props.children];

    //   <InfiniteList
    //   name={this.props.name}
    //   key='infinteList'
    //   // ref={this.scroller.scrollRef}
    //   initialPage={1}
    //   initialData={this.props.items.slice(0, 25)}
    //   // onScroll={this.handleScroll}
    //   // onLayout={this.handleLayout}
    //   // onContentSizeChange={this.handleContentLayout}
    //   dataLoader={(page) => {
    //     console.log("LOAD PAGE", page);
    //   }}
    //   itemRenderer={(item) => (
    //     <PopUpMenuItem
    //       icon={item.icon}
    //       iconSize='small'
    //       label={item.label}
    //       value={item.value}
    //       controlsLeft={
    //         this.props.multiple && (
    //           <Checkbox
    //             value={this.isSelectedValue(item.value)}
    //             onPress={() => this.handleSelect(item.value, item.label)}
    //           />
    //         )
    //       }
    //       key={item.value || "default"}
    //       style={[
    //         this.isSelectedValue(item.value) && styles.menuItemSelected,
    //         this.state.activeItemIdx === itemIdx && styles.menuItemActive,
    //       ]}
    //     />
    //   )}
    //   // itemLoadingRenderer={this.props.itemLoadingRenderer}
    //   keyExtractor={(item) => item.value}
    //   getItemLayout={(data, index) => ({
    //     length: 48,
    //     offset: 48 * index,
    //     index,
    //   })}
    //   itemSize={this.props.itemSize}
    //   //ItemLoadingComponent={<Text>Loading...</Text>}
    //   totalItems={this.props.items.length}
    //   itemsPerPage={50}
    //   height={48}
    // />

    return (
      <View
        style={[styles.container, this.props.controlStyle]}
        ref={this.containerRef}
        // Added onLayout=null to deal with measureLayout Bug RN android?
        onLayout={null}
      >
        {this.renderControl()}
        <Modal
          visible={this.state.isOpen}
          transparent
          animationType='none'
          style={styles.modal}
          backdrop
          onBackdropPress={this.handleBackdropPress}
        >
          <AnimatedView
            enter={{
              opacity: 1,
              transform: [{ translateX: 0 }, { translateY: 0 }, { scale: 1 }],
            }}
            exit={{
              opacity: 0,
              transform: this.exitTransformAnimation,
            }}
            duration={100}
            in={this.state.isOpen}
            ////////// style={[this.layoutStyles.popUpMenu, styles.popUpMenu, styles[this.props.direction]]}
            style={[
              styles.popUpMenu,
              this.layoutStyles.popUpMenu,
              this.props.style,
            ]}
            animateOnMount
            unmountOnExit
          >
            <Surface
              elevation={10}
              style={[styles.surface, this.props.style]}
              onLayout={this.handleLayout}
            >
              {this.props.HeaderComponent}
              {scrollComponent}
              {this.props.FooterComponent}
            </Surface>
          </AnimatedView>
        </Modal>
      </View>
    );
  }
  /** Caclulates the position of the popup and autofits it on screen */
  get layoutStyles() {
    let windowDimensions = Dimensions.get("window");
    let windowHeight = StatusBar.currentHeight
      ? windowDimensions.height - StatusBar.currentHeight
      : windowDimensions.height;
    let positionStyle = {
      top: null,
      right: null,
      bottom: null,
      left: null,
    };
    switch (this.props.direction) {
      case "down":
        positionStyle.top =
          this.state.controlLayout.top + this.state.controlLayout.height;
        positionStyle.left = this.state.controlLayout.left;
        break;
      case "downLeft":
        positionStyle.top =
          this.state.controlLayout.top + this.state.controlLayout.height;
        positionStyle.right =
          windowDimensions.width -
            (this.state.controlLayout.left + this.state.controlLayout.width) ||
          0;
        break;
      case "upLeft":
        positionStyle.right =
          windowDimensions.width -
            (this.state.controlLayout.left + this.state.controlLayout.width) ||
          0;
        positionStyle.bottom =
          windowHeight -
            this.state.controlLayout.top -
            this.state.controlLayout.height || 0;
        break;
      case "downRight":
        positionStyle.top =
          this.state.controlLayout.top + this.state.controlLayout.height;
        positionStyle.left = this.state.controlLayout.left;
        break;
      case "upRight":
        positionStyle.left = this.state.controlLayout.left;
        positionStyle.bottom =
          windowHeight -
            this.state.controlLayout.top -
            this.state.controlLayout.height || 0;
        break;
    }
    if (this.props.controlWidthCover) {
      positionStyle.minWidth = 80;
      if (this.state.controlLayout.width > 80)
        positionStyle.width = this.state.controlLayout.width;
    }
    if (positionStyle.top !== null) {
      let topOffset = positionStyle.top + this.state.popUpLayout.height;
      if (topOffset > windowDimensions.height) {
        positionStyle.top -= 8 + (topOffset - windowDimensions.height);
      }
    }
    if (positionStyle.left !== null) {
      let leftOffset = positionStyle.left + this.state.popUpLayout.width;

      if (leftOffset > windowDimensions.width) {
        positionStyle.left -= 8 + (leftOffset - windowDimensions.width);
      }
    }

    return StyleSheet.create({
      popUpMenu: {
        position: "absolute",
        ...positionStyle,
        // left: this.state.controlLayout.left,
        // width: this.state.controlLayout.width,
        // height: this.state.controlLayout.height,
      },
    });
  }
}
const styles = StyleSheet.create({
  container: {
    // position: "relative",
    // overflow: "visible"
  },
  modal: {},
  popUpMenu: {
    position: "absolute",
    flex: 1,
    flexDirection: "column",
    // flexWrap: "nowrap",
    zIndex: 10,
    // minWidth: 192
    maxHeight: 320,
  },
  surface: {
    borderRadius: 4,
    ...StyleSheet.padding(8, 0, 8, 0),
    flex: 1,
    zIndex: 1,
  },
  scrollView: {
    position: "relative",
    maxHeight: 320,
  },
  downRight: {
    top: 0,
    left: 0,
  },
  downLeft: {
    top: 0,
    right: 0,
  },
  upLeft: {
    bottom: 0,
    right: 0,
  },
  upRight: {
    bottom: 0,
    left: 0,
  },
});
